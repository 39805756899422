import apiClient from "../../../api";
import CustomDatepicker from "../../form/CustomDatepicker";
import Input from "../../form/Input";
import * as Dialog from "@radix-ui/react-dialog";
import Loading from "../../Loading";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import Button from "../../Button";
import moment from "moment-timezone";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export const dateWithoutTimezone = (date) => {
  return moment.utc(date).format("YYYY-MM-DD");
};

const RoundFormDialog = ({
  setRounds,
  tournament,
  rounds,
  open,
  onOpenChange,
  round,
}) => {
  const [loading, setLoading] = useState(false);
  const { tournamentId } = useParams();
  const { t } = useTranslation();
  const nextRound = round
    ? round["round-number"]
    : (rounds &&
        rounds.length > 0 &&
        rounds[rounds.length - 1]["round-number"] + 1) ||
      1;

  useEffect(() => {
    const currentRoundStartDate = round
      ? dateWithoutTimezone(round["start-time"])
      : null;
    const currentRoundEndDate = round
      ? dateWithoutTimezone(round["end-time"])
      : null;
    if (round) {
      setDates({
        startDate: {
          startDate: currentRoundStartDate,
          endDate: currentRoundStartDate,
        },
        endDate: {
          startDate: currentRoundEndDate,
          endDate: currentRoundEndDate,
        },
      });
    }
  }, [round]);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const handleStartDateChange = (date) => {
    setDates({ ...dates, startDate: date });
  };

  const handleEndDateChange = (date) => {
    setDates({ ...dates, endDate: date });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const round_number = formData.get("round_number");
    const default_frames_count = formData.get("default_frames_count");
    const payload = {
      round_number,
      start_time: dates.startDate.startDate,
      end_time: dates.endDate.startDate,
      tournament_id: tournamentId,
      default_frames_count: default_frames_count,
    };
    setLoading(true);
    if (round) {
      payload.round_id = round["round-id"];
      apiClient
        .put(`/tournaments/${tournamentId}/rounds`, payload)
        .then((res) => {
          setRounds(
            rounds.map((round) => {
              if (round["round-id"] === res.data["round-id"]) {
                return res.data;
              }
              return round;
            }),
          );
          onOpenChange(false);
          toast.success(t("entities.round.crud_success.update"));
        })
        .catch((error) => {
          console.error(error);
          toast.error(t("entities.round.crud_error.update"));
        })
        .finally(() => setLoading(false));
    } else {
      apiClient
        .post(`/tournaments/${tournamentId}/rounds`, payload)
        .then((res) => {
          setRounds([...rounds, { ...res.data, matches: [] }]);
          onOpenChange(false);
          toast.success(t("entities.round.crud_success.create"));
        })
        .catch((error) => {
          console.error(error);
          toast.error(t("entities.round.crud_error.create"));
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <Button
          color="primary"
          className="w-full inline-block md:w-fit"
          onClick={(e) => {
            e.preventDefault();
            onOpenChange(true);
          }}
        >
          {t("entities.round.crud.create")}
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
        <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4">
          <div className="bg-white rounded-md shadow-lg px-4 py-6 flex flex-col gap-4">
            <div className="flex flex-col justify-between gap-4">
              <Dialog.Title className="text-lg font-medium text-gray-800">
                {round
                  ? t("entities.round.crud.update")
                  : t("entities.round.crud.create")}
              </Dialog.Title>
              {loading ? (
                <Loading />
              ) : (
                <form onSubmit={onSubmit} className="flex flex-col gap-4">
                  <Input
                    placeholder="ex: 1"
                    label={t("entities.round.form.round_number")}
                    id="round_number"
                    name="round_number"
                    required
                    defaultValue={nextRound}
                  />
                  <Input
                    placeholder="ex: 3"
                    label={t("entities.round.form.frames_count")}
                    id="default_frames_count"
                    name="default_frames_count"
                    required
                    defaultValue={
                      round
                        ? round["default-frames-count"]
                        : tournament["default-frames-count"]
                    }
                  />
                  <CustomDatepicker
                    value={dates.startDate}
                    asSingle={true}
                    label={t("entities.round.form.start_time")}
                    primaryColor={"blue"}
                    inputClassName="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border 'border-gray-200 focus:border-gray-400 placeholder-gray-500 text-sm focus:outline-none  focus:bg-white"
                    onChange={handleStartDateChange}
                    displayFormat={"DD/MM/YYYY"}
                    useRange={false}
                    placeholder={t("entities.round.form.start_time")}
                    required
                  />
                  <CustomDatepicker
                    value={dates.endDate}
                    asSingle={true}
                    primaryColor={"blue"}
                    label={t("entities.round.form.end_time")}
                    inputClassName="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border 'border-gray-200 focus:border-gray-400 placeholder-gray-500 text-sm focus:outline-none  focus:bg-white"
                    onChange={handleEndDateChange}
                    placeholder={t("entities.round.form.end_time")}
                    useRange={false}
                    required
                    displayFormat={"DD/MM/YYYY"}
                  />
                  <div className="inline-block">
                    <Button type="submit" color="primary">
                      {round
                        ? t("entities.round.crud.update")
                        : t("entities.round.crud.create")}
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default RoundFormDialog;
