import React, { useState } from "react";
import { useLoaderData } from "react-router";
import SectionHeader from "../../SectionHeader";
import CalendarIcon from "../../CalendarIcon";
import moment from "moment";
import SectionSubHeader from "../../SectionSubHeader";
import Alert from "../../common/Alert";
import Button from "../../Button";
import Input from "../../form/Input";
import apiClient from "../../../api";
import Loading from "../../Loading";
import * as Dialog from "@radix-ui/react-dialog";
import UL from "../../UL";
import UserListItem from "../users/UserLIstItem";
import SimpleListItem from "../users/SimpleListItem";
import { useTranslation } from "react-i18next";

const InvitePlayerForm = ({
  onInviteSuccess,
  tournament,
  onComplete,
  setLoading,
  invited,
}) => {
  const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email");
    if (!email) {
      return alert(t("entities.user.form.invalid_email"));
    }
    setLoading(true);
    await apiClient
      .post(`/tournaments/${tournament["tournament-id"]}/players`, {
        tournament_id: tournament["tournament-id"],
        email,
      })
      .then((res) => {
        onInviteSuccess(res.data);
      })
      .catch((error) => {
        if (error?.response?.data) {
          console.error(error.response.data);
        }
      })
      .finally(() => {
        if (onComplete) {
          onComplete();
        }
        setLoading(false);
      });
  };
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <Input
        name="email"
        type="email"
        placeholder={t("entities.tournament.form.invitation_email_placeholder")}
      />
      <Button type="submit">{t("entities.tournament.form.send_invite")}</Button>
      {invited && (
        <Alert
          type="success"
          title={t("labels.success")}
          message={t("entities.invitation.crud_success.create")}
        />
      )}
    </form>
  );
};

const Tournament = () => {
  const tournament = useLoaderData();
  const startDate = moment.utc(new Date(tournament["start-time"]));
  const endDate =
    tournament["end-time"] && moment.utc(new Date(tournament["end-time"]));
  const [invitationOpen, setInvitationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invited, setInvited] = useState(false);
  const { t } = useTranslation();
  const onInviteSuccess = () => {
    setInvited(true);
  };

  return (
    <div>
      <SectionHeader
        title={tournament.name}
        description={tournament.description}
      />
      <div className="flex flex-col md:flex-row items-start mt-4 gap-8 text-center ">
        <div className="flex flex-wrap md:flex-nowrap justify-around gap-8 w-full md:w-auto">
          <div>
            <div className="text-2lg font-bold">{t("labels.start_date")}</div>
            <CalendarIcon
              day={startDate.format("DD")}
              month={startDate.format("MMMM")}
              dayOfWeek={startDate.format("dddd")}
            />
          </div>

          {endDate && (
            <div>
              <div className="text-2lg font-bold">
                {" "}
                {t("labels.start_date")}
              </div>
              <CalendarIcon
                day={endDate.format("DD")}
                month={endDate.format("MMMM")}
                dayOfWeek={endDate.format("dddd")}
              />
            </div>
          )}
        </div>
        <div className="text-left w-full md:max-w-md  flex flex-col gap-4 items-start md:items-start mx-auto md:m-0 shadow-lg border rounded-md duration-300 hover:shadow-sm p-4">
          <SectionSubHeader
            title={t("entities.tournament.labels.players_list")}
          />
          {tournament.players.length === 0 && (
            <Alert
              type="info"
              title={t("entities.tournament.labels.empty_players")}
              dismissible={false}
            />
          )}
          <UL>
            {tournament.players.map((player) => {
              return (
                <UserListItem
                  key={player["player-id"]}
                  name={player.name || "Sem nome"}
                />
              );
            })}
          </UL>
          <Dialog.Root open={invitationOpen} onOpenChange={setInvitationOpen}>
            <Dialog.Trigger asChild>
              <Button
                color="primary"
                className="w-full md:w-auto"
                onClick={() => setInvitationOpen(true)}
              >
                {t("entities.tournament.form.send_invite")}
              </Button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
              <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4">
                <div className="bg-white rounded-md shadow-lg px-4 py-6 flex flex-col gap-4">
                  <div className="flex flex-col justify-between gap-4">
                    <Dialog.Title className="text-lg font-medium text-gray-800">
                      {t("entities.tournament.form.send_invite")}
                    </Dialog.Title>
                    {loading ? (
                      <Loading />
                    ) : (
                      <InvitePlayerForm
                        onInviteSuccess={onInviteSuccess}
                        tournament={tournament}
                        setLoading={setLoading}
                        invited={invited}
                      />
                    )}
                  </div>
                </div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </div>
        <div className="text-left w-full md:max-w-md  flex flex-col gap-4 items-start md:items-start mx-auto md:m-0 shadow-lg border rounded-md duration-300 hover:shadow-sm p-4">
          <SectionSubHeader
            title={t("entities.tournament.labels.rounds_list")}
          />
          {tournament.rounds.length === 0 && (
            <Alert
              type="info"
              title={t("entities.tournament.labels.empty_rounds")}
              dismissible={false}
            />
          )}
          <UL>
            {tournament.rounds.map((round, idx) => {
              return (
                <SimpleListItem
                  text={`Rodada ${round["round-number"] || idx}`}
                />
              );
            })}
            <Button link={"rounds"} color="primary">
              {t("entities.tournament.labels.manage_rounds")}
            </Button>
          </UL>
        </div>
      </div>
    </div>
  );
};

export default Tournament;
