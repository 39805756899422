import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Button = ({ color = "primary", children, onClick, link, className }) => {
  const buttonClasses = `inline-flex items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded py-4 px-6 text-sm font-medium tracking-wide  transition duration-300  focus-visible:outline-none disabled:cursor-not-allowed   disabled:shadow-none ${className || ""}`;

  const primaryClasses = `text-white bg-emerald-500 hover:bg-emerald-600 focus:bg-emerald-700 disabled:border-emerald-300 disabled:bg-emerald-300' ${buttonClasses}`;
  const secondaryClasses = `bg-emerald-50 text-emerald-500 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400' ${buttonClasses}`;

  const buttonProps = {
    className: color === "primary" ? primaryClasses : secondaryClasses,
    onClick: onClick,
  };
  if (link) {
    return (
      <Link
        to={link}
        {...buttonProps}
        className={`${buttonProps.className} inline-block`}
      >
        {children}
      </Link>
    );
  }
  return <button {...buttonProps}>{children}</button>;
};

Button.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"]).isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  link: PropTypes.string,
};

export default Button;
