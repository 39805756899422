import React from "react";

const IconButton = ({ icon, className = "", ...props }) => {
  return (
    <button
      {...props}
      className={`inline-flex h-12 items-center justify-center gap-2 self-center  whitespace-nowrap rounded bg-emerald-50 px-6 text-sm font-medium tracking-wide text-emerald-500 transition duration-300 hover:bg-emerald-100 hover:text-emerald-600 focus:bg-emerald-200 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-100 disabled:text-emerald-400 disabled:shadow-none ${className}`}
    >
      <span className="relative only:-mx-6">{icon}</span>
    </button>
  );
};

export default IconButton;
