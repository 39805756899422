import Input from "../../components/form/Input";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import useLogin from "../../hooks/useLogin";

/**
 * Sign In component.
 *
 * @component
 * @returns {JSX.Element} Sign In form.
 */
const SignIn = () => {
  const formRef = useRef({});
  const { login, result, errors, loading } = useLogin();

  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");
    formRef.current = { email, password };
    await login(email, password);
  }

  return (
    <>
      <div className="my-8 border-b text-center">
        <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
          Logar com meu email
        </div>
      </div>

      {result && result.error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Oops! </strong>
          <span className="block sm:inline">
            {" "}
            Verifique os dados inseridos e tente novamente..
          </span>
        </div>
      )}

      {loading && <Loading />}

      {(result || (!result && !loading)) && (
        <form onSubmit={handleSubmit} className="w-full">
          <div className="flex flex-col gap-4">
            <Input
              type="email"
              name="email"
              placeholder="Email"
              defaultValue={formRef.current.email}
              required
              errors={errors}
            />

            <Input
              type="password"
              name="password"
              defaultValue={formRef.current.password}
              required
              placeholder="Password"
              errors={errors}
            />

            <Link
              to="/auth/forgot-password"
              className="border-b border-gray-200 pb-4 text-center"
            >
              Esqueci a minha senha
            </Link>

            <button
              type="submit"
              className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
            >
              <span className="ml-3">Entrar</span>
            </button>

            <Link
              to="/auth/signup"
              type="submit"
              className="mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none  bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
            >
              <svg
                className="w-6 h-6 -ml-2"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                <circle cx="8.5" cy="7" r="4" />
                <path d="M20 8v6M23 11h-6" />
              </svg>
              <span className="ml-3">Criar minha conta</span>
            </Link>
          </div>
        </form>
      )}
    </>
  );
};

export default SignIn;
