import apiClient from "../../api";
import Input from "../../components/form/Input";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import Alert from "../../components/common/Alert";

/**
 * Sign Up component.
 *
 * This component is responsible for rendering the sign up form and handling form submission.
 * It uses the `useAuth` hook to access the authentication context and perform API requests.
 *
 * @returns {JSX.Element} The rendered sign up form.
 */
const SignUp = () => {
  const [errors, setErrors] = useState();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const formRef = useRef({});

  function handleSubmit(event) {
    event.preventDefault();
    setErrors(null);
    const formData = new FormData(event.currentTarget);
    const name = formData.get("name");
    const email = formData.get("email");
    formRef.current = { name, email };
    setLoading(true);
    apiClient
      .post("/auth/signup", { name, email })
      .then((_) => {
        setResult(true);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div className="my-8 border-b text-center">
        <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
          Criar minha conta
        </div>
      </div>
      {result && (
        <div>
          <Alert
            title="Conta criada com sucesso!"
            type="success"
            dismissible={false}
            message="Você receberá em instantes no seu email, uma mensagem contendo um link para definir a sua senha de acesso. Lembre-se de verificar também na caixa de spam."
          />

          <Link
            className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full l py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
            to="/auth/login"
          >
            Acessar minha conta
          </Link>
        </div>
      )}
      {loading && <Loading />}
      {errors && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Oops! Algo deu errado. </strong>
          <span className="block sm:inline">
            {" "}
            Verifique os dados inseridos e tente novamente..
          </span>
        </div>
      )}
      {!result && !loading && (
        <form onSubmit={handleSubmit} className="w-full">
          <div className="flex flex-col gap-4">
            <Input
              type="text"
              name="name"
              errors={errors}
              defaultValue={formRef.current.name}
              required
              placeholder="Seu nome"
            />

            <Input
              type="email"
              name="email"
              errors={errors}
              defaultValue={formRef.current.email}
              required
              placeholder="Email"
            />

            <button
              type="submit"
              className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
            >
              <svg
                className="w-6 h-6 -ml-2"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                <circle cx="8.5" cy="7" r="4" />
                <path d="M20 8v6M23 11h-6" />
              </svg>
              <span className="ml-3">Criar conta</span>
            </button>
            <Link
              to="/auth/login"
              type="submit"
              className="mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none  bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
            >
              <span className="ml-3">Já tenho uma conta</span>
            </Link>
          </div>
        </form>
      )}
    </>
  );
};
export default SignUp;
