import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import useAuth from "../auth/useAuth";
import apiClient from "../api";

// Custom hook for handling login functionality
const useLogin = () => {
  // State variables
  const [errors, setErrors] = useState({});
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  // Hooks
  const navigate = useNavigate();
  const { setToken } = useAuth();

  // Login function
  const login = useCallback(
    async (email, password) => {
      setLoading(true);
      try {
        const response = await apiClient.post("/auth/signin", {
          email,
          password,
        });
        setToken(response.data.token);
        navigate("/", { replace: true });
        setResult(response.data);
      } catch (error) {
        setErrors(error.response.data);
        setResult({ error: error.response.data });
      } finally {
        setLoading(false);
      }
    },
    [navigate, setToken],
  );

  // Return values
  return { login, result, errors, loading };
};

export default useLogin;
