import React from "react";
import { useTranslation } from "react-i18next";
import Datepicker from "react-tailwindcss-datepicker";

const CustomDatepicker = ({ label, id, name, ...props }) => {
  const { i18n } = useTranslation();
  return (
    <div>
      {label && (
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor={id ? id : name ? name : undefined}
        >
          {label}
        </label>
      )}

      <Datepicker
        name={name}
        i18n={i18n.language}
        id={id}
        {...props}
        inputClassName="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border 'border-gray-200 focus:border-gray-400 placeholder-gray-500 text-sm focus:outline-none  focus:bg-white"
        displayFormat={"DD/MM/YYYY"}
      />
    </div>
  );
};

export default CustomDatepicker;
