import React from "react";
import Status from "../../Status";
import FormatedDate from "../../FormatedDate";
import MatchFramesTable from "./MatchFramesTable";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import IconButton from "../../IconButton";
import DeleteMatchModal from "./DeleteMatchModal";

const MatchCard = ({ match, idx, handleDeleteMatch, handleEditMatch }) => {
  return (
    <div className="w-full md:w-1/3 p-2 border rounded">
      <div className="flex items-center gap-2 bg-gray-50 rounded p-2 border text-xs">
        <span>#{idx}</span>

        {match["start-time"] && (
          <>
            {" "}
            - <FormatedDate
              className="text-xs"
              date={match["start-time"]}
            /> -{" "}
          </>
        )}
        <Status status={match.status} />
        <div className="md:ml-auto flex gap-2">
          <IconButton
            icon={<EditIcon />}
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              handleEditMatch(match);
            }}
          />
          <DeleteMatchModal
            onConfirm={(e) => {
              e.preventDefault();
              handleDeleteMatch(match);
            }}
          />
        </div>
      </div>
      <MatchFramesTable match={match} />
    </div>
  );
};

export default MatchCard;
