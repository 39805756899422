import React from "react";

const CalendarIcon = ({ month, day, dayOfWeek, time }) => {
  return (
    <div className="min-w-32 bg-white font-medium">
      <div className="w-32 flex-none rounded-t lg:rounded-t-none lg:rounded-l text-center shadow-lg">
        <div className="block rounded-t overflow-hidden text-center">
          <div className="bg-blue-500 text-white py-1">{month}</div>
          <div className="pt-1 border-l border-r border-white bg-white">
            <span className="text-5xl font-bold leading-tight">{day}</span>
          </div>
          <div className="border-l border-r border-b rounded-b-lg text-center border-white bg-white -pt-2 -mb-1">
            <span className="text-sm">{dayOfWeek}</span>
          </div>
          <div className="pb-2 border-l border-r border-b rounded-b-lg text-center border-white bg-white">
            <span className="text-xs leading-normal">{time}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarIcon;
