const SectionHeader = ({ title, description }) => {
  return (
    <div className="mx-auto">
      <div className="items-start justify-between py-4 border-b md:flex">
        <div>
          <h3 className="text-gray-800 text-2xl font-bold">{title}</h3>
          <p className="text-gray-600 mt-2">{description}</p>
        </div>
      </div>
    </div>
  );
};
export default SectionHeader;
