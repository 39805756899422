import { Outlet, useLocation, useNavigate } from "react-router";
import LoginBg2 from "../assets/images/bg-login2.png";
import GreenBall from "../assets/images/greenball.png";
import useAuth from "../auth/useAuth";
import { useEffect } from "react";

const AuthLayout = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (token) {
      navigate("/", { replace: true });
      return;
    } else if (location.pathname === "/") {
      navigate("/auth/login", { replace: true });
    }
  }, [token, navigate, location.pathname]);

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
      <div className="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 flex flex-col justify-center">
          <div className="flex items-center text-center justify-center gap-2">
            <img alt="#" src={GreenBall} className="w-4" />
            <div className="subpixel-antialiased underline decoration-[#789e2f] font-mono">
              Placar Sinuca
            </div>
          </div>
          <div className="mt-2 flex flex-col items-center">
            <div className="w-full flex-1 justify-center flex flex-col items-center gap-4">
              <Outlet />
            </div>
          </div>
        </div>
        <div className="flex-1 bg-[#789e2f] text-center hidden lg:flex">
          <div
            className="w-full bg-contain bg-bottom bg-right bg-no-repeat rounded-lg"
            style={{ backgroundImage: `url(${LoginBg2})` }}
          >
            {/* https://static.vecteezy.com/system/resources/previews/012/626/263/original/snooker-cartoon-colored-clipart-illustration-free-vector.jpg */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
