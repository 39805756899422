import { useState } from "react";
import { ReactComponent as Trophy } from "../../assets/icons/trophy.svg";
import { ReactComponent as Team } from "../../assets/icons/team.svg";
import { ReactComponent as Avatar } from "../../assets/icons/avatar.svg";
import { ReactComponent as RedBall } from "../../assets/icons/red_ball.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { Link } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const languages = [
    { language: "pt-BR", flag: "br", name: "Português" },
    { language: "en", name: "English", flag: "gb" },
    { language: "zh-CN", name: "Chinese (Simplified)", flag: "cn" },
    { language: "hi-IN", name: "Hindi", flag: "in" },
    { language: "th", name: "Thai", flag: "th" },
    { language: "de", name: "German", flag: "de" },
    { language: "nl", name: "Dutch", flag: "nl" },
    { language: "fr", name: "French", flag: "fr" },
    { language: "ja", name: "Japanese", flag: "jp" },
    { language: "es", name: "Spanish", flag: "es" },
  ];
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="flex flex-wrap items-center gap-2 mt-4">
      {languages.map((lang) => (
        <button
          key={lang.language}
          className="inline-flex items-center justify-center w-auto rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => changeLanguage(lang.language)}
        >
          <span className={`fi fi-${lang.flag}`}></span>
        </button>
      ))}
    </div>
  );
};

const Sidebar = () => {
  const { user, loading } = useAuth();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigation = [
    {
      href: "/halls",
      name: t("sidebar.halls"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
          />
        </svg>
      ),
    },
    {
      href: "/tournaments",
      name: t("sidebar.tournaments"),
      icon: <Trophy />,
    },
    {
      href: "/teams",
      name: t("sidebar.teams"),
      icon: <Team />,
    },
  ];

  return (
    <>
      <nav className="fixed md:hidden w-full px-4 py-4 flex justify-between items-center bg-white z-9">
        <Link
          className="flex justify-center items-center text-md font-semibold leading-none"
          to="/"
        >
          <RedBall />
          <span>Placar Sinuca</span>
        </Link>
        <div className="lg:hidden">
          <button
            className="navbar-burger flex items-center text-grey-600 p-3"
            onClick={() => setOpen(!open)}
          >
            <svg
              className="block h-4 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
      </nav>
      <nav
        className={`fixed top-0 left-0 w-full h-full border-r bg-white space-y-8 sm:w-80
      transition-transform ${!open ? "-translate-x-full md:translate-x-0" : ""}`}
      >
        <div className="flex flex-col h-full px-4">
          <div className="h-20 flex items-center pl-2">
            {loading ? (
              <Loading />
            ) : (
              user && (
                <div className="w-full flex items-center gap-x-4 text-gray-600">
                  <Avatar />
                  <div>
                    <span className="block text-gray-700 text-sm font-semibold">
                      {user.name}
                    </span>
                    <span className="block mt-px text-gray-600 text-xs">
                      Plano gratuito
                    </span>
                  </div>
                </div>
              )
            )}
            <div className="relative flex-1 text-right md:hidden">
              <button
                className="p-1.5 rounded-md text-gray-500 hover:bg-gray-50 active:bg-gray-100"
                onClick={() => setOpen(!open)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
          <div className="overflow-auto">
            <ul className="text-sm font-medium flex-1">
              {navigation.map((item, idx) => (
                <li key={idx}>
                  <Link
                    to={item.href}
                    onClick={() => setOpen(!open)}
                    className="flex items-center gap-x-2 text-gray-600 p-2 rounded-lg  hover:bg-gray-50 active:bg-gray-100 duration-150"
                  >
                    <div className="text-gray-500">{item.icon}</div>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <LanguageSelector />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
