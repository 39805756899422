import i18n from "../../../i18n";

const getMatchStatus = () => {
  return [
    { value: "scheduled", text: i18n.t("entities.match.status.scheduled") },
    { value: "ongoing", text: i18n.t("entities.match.status.ongoing") },
    { value: "finished", text: i18n.t("entities.match.status.finished") },
    { value: "canceled", text: i18n.t("entities.match.status.canceled") },
  ];
};

export default getMatchStatus;
