import axios from "axios";

const prod =
  window.location.hostname.includes("heroku") ||
  window.location.hostname.includes("placarsinuca");

const apiClient = axios.create({
  baseURL: prod
    ? "https://placarsinuca.com.br/api"
    : "http://localhost:8085/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiClient;
