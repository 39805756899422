import { Link } from "react-router-dom";

const Card = ({ title, description, icon, linkTo = "#", footer = null }) => {
  return (
    <div className="shadow-lg border rounded-md duration-300 hover:shadow-sm p-4">
      <Link to={linkTo} className="space-y-3">
        <div className="flex items-center gap-x-5">
          <div className="bg-white w-14 h-14 flex items-center justify-center">
            {icon}
          </div>
          <div>
            <span className="block text-sm text-indigo-600 font-medium">
              {title}
            </span>
          </div>
        </div>
        <p className="text-gray-600 sm:text-sm">{description}</p>
        {footer}
      </Link>
    </div>
  );
};

export default Card;
