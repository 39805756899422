import React from "react";

const SimpleListItem = ({ text }) => {
  return (
    <li className="flex items-start gap-4 px-4 py-3">
      <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0">
        <h4 className="text-base text-slate-700 ">{text}</h4>
      </div>
    </li>
  );
};

export default SimpleListItem;
