import React, { useState } from "react";
import SectionHeader from "../../SectionHeader";
import { useLoaderData } from "react-router";
import Button from "../../Button";
import TournamentIcon from "../../../assets/images/tournament.svg";
import Loading from "../../Loading";
import apiClient from "../../../api";
import Alert from "../../common/Alert";

const TournamentInvitation = () => {
  const invitation = useLoaderData();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(invitation["status"] === "confirmed");

  const confirmInvitation = async () => {
    setLoading(true);
    await apiClient
      .post(
        `/tournaments/${invitation["tournament-id"]}/invitations/${invitation["invitation-id"]}/confirm`,
      )
      .then((res) => {
        setSuccess(res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <SectionHeader title={"Confirmar inscrição"} />
      <div className="mt-4 flex flex-col gap-4 ">
        {loading ? (
          <Loading />
        ) : success ? (
          <Alert
            type="success"
            title="Sucesso!"
            message="Sua inscrição foi confirmada com sucesso!"
          ></Alert>
        ) : (
          <>
            <img width="100" src={TournamentIcon} alt="torneio" />
            <p>
              Você foi convidado para participar to torneio
              <span className="font-bold">
                {" "}
                {invitation["tournament-name"]}
              </span>{" "}
              em {invitation["hall-name"]}
            </p>
            <p>Para confirmar sua inscrição, basta clicar no botão abaixo</p>
            <div className="inline-block">
              <Button onClick={confirmInvitation}>Confirmar inscrição</Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TournamentInvitation;
