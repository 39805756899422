import moment from "moment";
import React from "react";
export const formatedLocaleDate = (date) => {
  return moment.utc(date).format(moment.localeData().longDateFormat("LL"));
}
const FormatedDate = ({ date, ...props }) => {
  return (
    <span {...props}>
      {formatedLocaleDate(date)}
    </span>
  );
};

export default FormatedDate;
