import "./App.css";
import * as React from "react";
import AuthProvider from "./auth/AuthProvider";
import Routes from "./routing/routes";
import { Toaster } from "sonner";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/zh-cn";
import "moment/locale/hi";
import "moment/locale/en-gb";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/ja";
import "moment/locale/nl";
import "moment/locale/th";
function App() {
  const { i18n } = useTranslation();
  React.useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);
  return (
    <AuthProvider>
      <Routes />
      <Toaster />
    </AuthProvider>
  );
}

export default App;
