import { RouterProvider, createBrowserRouter } from "react-router-dom";
import useAuth from "../auth/useAuth";
import SignIn from "../pages/auth/SignIn";
import Logout from "../pages/Logout";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import AuthLayout from "../layouts/AuthLayout";
import AppLayout from "../layouts/AppLayout";
import Halls from "../components/app/halls/Halls";
import apiClient from "../api";
import Hall from "../components/app/halls/Hall";
import TournamentForm from "../components/app/tournaments/TournamentForm";
import Tournament from "../components/app/tournaments/Tournament";
import TournamentInvitation from "../components/app/tournaments/TournamentInvitation";
import SectionHeader from "../components/SectionHeader";
import Rounds from "../components/app/tournaments/Rounds";

const InProgress = () => {
  return (
    <SectionHeader title="Em construção" description="Página em construção" />
  );
};

const Routes = () => {
  const { token } = useAuth();

  const routes = [
    {
      path: "/",
      element: token ? <AppLayout /> : <AuthLayout />,
      children: [
        {
          path: "halls", // Modify the path to remove the leading slash
          element: <Halls />,
          loader: async () => {
            return await apiClient.get("/halls").then((res) => {
              return res.data;
            });
          },
        },
        {
          path: "halls/:hallId", // Remove the leading slash to make the path relative
          element: <Hall />,
          loader: async ({ params }) => {
            return await apiClient
              .get(`/halls/${params.hallId}`)
              .then((res) => {
                return res.data;
              });
          },
        },
        {
          path: "halls/:hallId/tournaments/new",
          element: <TournamentForm />,
        },
        {
          path: "tournaments",
          element: <InProgress />,
        },
        {
          path: "teams",
          element: <InProgress />,
        },
        {
          path: "tournaments/:tournamentId/invitations/:invitationId",
          loader: async ({ params }) => {
            return await apiClient
              .get(
                `/tournaments/${params.tournamentId}/invitations/${params.invitationId}`,
              )
              .then((res) => {
                return res.data;
              });
          },
          element: <TournamentInvitation />,
        },
        {
          path: "tournaments/:tournamentId",
          loader: async ({ params }) => {
            return await apiClient
              .get(`/tournaments/${params.tournamentId}`)
              .then((res) => {
                return res.data;
              });
          },
          element: <Tournament />,
        },
        {
          path: "tournaments/:tournamentId/rounds",
          loader: async ({ params }) => {
            const [tournament, rounds] = await Promise.all([
              apiClient
                .get(`/tournaments/${params.tournamentId}`)
                .then((res) => {
                  return res.data;
                }),
              apiClient
                .get(`/tournaments/${params.tournamentId}/rounds`)
                .then((res) => {
                  return res.data;
                }),
            ]);
            return { tournament, rounds };
          },
          element: <Rounds />,
        },
      ],
    },

    {
      path: "/auth",
      element: <AuthLayout />,
      children: [
        {
          path: "reset-password/:resetToken",
          element: <ResetPassword />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "login",
          element: <SignIn />,
        },
        {
          path: "logout",
          element: <Logout />,
        },
        {
          path: "signup",
          element: <SignUp />,
        },
      ],
    },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default Routes;
