import React from "react";
import { useLoaderData } from "react-router";
import SectionHeader from "../../SectionHeader";
import Button from "../../Button";
import SectionSubHeader from "../../SectionSubHeader";
import Alert from "../../common/Alert";
import Card from "../../Card";
import TournamentIcon from "../../../assets/images/tournament.svg";
import { useTranslation } from "react-i18next";

const Hall = () => {
  const hall = useLoaderData();
  const { t } = useTranslation();
  if (!hall) {
    return null;
  }

  return (
    hall && (
      <div>
        <SectionHeader
          title={hall.name}
          description={t("entities.hall.page.single.description")}
        />
        <div className="content py-8">
          <div className="gap-8 flex flex-col">
            <SectionSubHeader title={t("entities.tournament.labels.list")} />
            {hall?.tournaments.length === 0 && (
              <Alert
                className="w-full"
                dismissible={false}
                type="info"
                message={t("entities.tournament.labels.empty")}
              />
            )}
            {hall?.tournaments.map((t) => {
              return (
                <div key={t["tournament-id"]} className="w-full">
                  <Card
                    title={t.name}
                    linkTo={`/tournaments/${t["tournament-id"]}`}
                    icon={<img src={TournamentIcon} alt="torneio" />}
                    footer={
                      t.description && (
                        <div className="text-sm text-gray-600 flex items-center gap-6">
                          <span className="flex items-center gap-2">
                            {t.description}
                          </span>
                        </div>
                      )
                    }
                  />
                </div>
              );
            })}
            <div>
              <Button
                color="primary"
                link={`/halls/${hall["hall-id"]}/tournaments/new`}
              >
                {t("entities.tournament.crud.create")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Hall;
