import apiClient from "../../api";
import { useParams } from "react-router";
import Input from "../../components/form/Input";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../components/common/Alert";
import Loading from "../../components/Loading";
import useLogin from "../../hooks/useLogin";

/**
 * Component for resetting the password.
 *
 * @component
 * @example
 * return (
 *   <ResetPassword />
 * )
 */
const ResetPassword = () => {
  const { resetToken } = useParams();
  const [errors, setErrors] = useState({});
  const [result, setResult] = useState();
  const [invalidToken, setInvalidToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const { login } = useLogin();

  const findResetToken = useCallback(async () => {
    setLoading(true);
    await apiClient
      .get(`/auth/find-reset-token/${resetToken}`)
      .then((res) => {
        setUserEmail(res.data.email);
      })
      .catch((_) => {
        setInvalidToken(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [resetToken]);

  useEffect(() => {
    findResetToken();
  }, [findResetToken]);

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const password = formData.get("password");
    const passwordCheck = formData.get("password_check");

    if (password !== passwordCheck) {
      return alert("As senhas inseridas não são iguais!");
    }
    setLoading(true);
    apiClient
      .post("/auth/reset-password", { "reset-token": resetToken, password })
      .then((_) => {
        setResult(true);
        if (userEmail) {
          login(userEmail, password);
        }
      })
      .catch((err) => {
        setErrors(err.response?.data);
        setResult({ error: "invalid_reset_token" });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  if (invalidToken) {
    return (
      <>
        <Alert
          type="danger"
          message="O link de definição de senha que você está tentando utilizar é inválido ou ja expirou!
        Para tentar redefinir sua senha novamente, clique no botão baixo."
        />
        <Link
          to="/auth/forgot-password"
          className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
        >
          Esqueci a minha senha
        </Link>
      </>
    );
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div className="my-4 border-b text-center">
        <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
          Definir minha senha
        </div>
      </div>
      {!result && (
        <form onSubmit={handleSubmit} className="w-full">
          <div>
            <Input
              className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
              type="password"
              name="password"
              required
              placeholder="Nova senha"
              errors={errors}
            />

            <Input
              className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
              type="password"
              name="password_check"
              required
              placeholder="Repetir nova senha"
              errors={errors}
            />

            <button
              type="submit"
              className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
            >
              <span className="ml-3">Definir senha</span>
            </button>
          </div>
        </form>
      )}
      {result && (
        <Alert
          type="success"
          message="Senha alterada com sucesso! Você já pode utilizar a sua nova senha, clique no botão abaixo para entrar com sua conta."
        />
      )}
    </>
  );
};
export default ResetPassword;
