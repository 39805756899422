import React from "react";
import { useTranslation } from "react-i18next";

const tdClass =
  "before:w-24 before:inline-block before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] sm:before:content-none flex items-center sm:table-cell h-12 px-6 text-sm transition duration-300 sm:border-t sm:border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500";

const FramesTableHeader = ({ frames }) => {
  const { t } = useTranslation();
  const className =
    "hidden h-12 px-6 text-sm font-medium border-l sm:table-cell first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100";
  if (!frames || frames.length === 0) {
    return (
      <th scope="col" className={className}>
        {t("entities.match.no_frames")}
      </th>
    );
  }
  return frames.map((frame) => {
    return (
      <th
        scope="col"
        key={`${frame["frame-id"]}-frame-number`}
        className={className}
      >
        #{frame["frame-number"]}
      </th>
    );
  });
};
const FramesTablePoints = ({ frames, userScoreIndex }) => {
  if (!frames || frames.length === 0) {
    return (
      <td data-th="Points" className={tdClass}>
        -
      </td>
    );
  }
  return frames.map((frame) => {
    return (
      <td
        key={`${frame["frame-id"]}-frame-points`}
        data-th="Points"
        className={tdClass}
      >
        {frame[userScoreIndex]}
      </td>
    );
  });
};

const MatchFramesTable = ({ match }) => {
  const { t } = useTranslation();
  return (
    <table
      className="w-full text-left border border-separate rounded border-slate-200"
      cellSpacing="0"
    >
      <tbody>
        <tr>
          <th
            scope="col"
            className="hidden h-12 px-6 text-sm font-medium border-l sm:table-cell first:border-l-0 stroke-slate-700 text-slate-700 bg-slate-100"
          >
            {t("labels.player")}
          </th>
          <FramesTableHeader frames={match.frames} />
        </tr>
        <tr className="block border-b sm:table-row last:border-b-0 border-slate-200 sm:border-none">
          <td data-th="Player" className={tdClass}>
            {match["user-one"].name || match["user-one"].email}
          </td>
          <FramesTablePoints
            frames={match.frames}
            userScoreIndex="user-one-score"
          />
        </tr>
        <tr className="block border-b sm:table-row last:border-b-0 border-slate-200 sm:border-none">
          <td data-th="Player" className={tdClass}>
            {match["user-two"].name || match["user-two"].email}
          </td>
          <FramesTablePoints
            frames={match.frames}
            userScoreIndex="user-two-score"
          />
        </tr>
      </tbody>
    </table>
  );
};

export default MatchFramesTable;
