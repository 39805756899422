import React from "react";
import PropTypes from "prop-types";

const Select = ({ options, errors, name, label, id, ...rest }) => {
  const hasErrors = errors && !!errors[name];
  return (
    <div>
      {label && (
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor={id ? id : name ? name : undefined}
        >
          {label}
        </label>
      )}
      <select
        className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${hasErrors ? "border-red-500" : "border-gray-200 focus:border-gray-400"} placeholder-gray-500 text-sm focus:outline-none  focus:bg-white`}
        name={name}
        {...rest}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Select;
