import apiClient from "../../../api";
import * as Dialog from "@radix-ui/react-dialog";
import Loading from "../../Loading";
import { useParams } from "react-router";
import { useRef, useState } from "react";
import Button from "../../Button";
import Select from "../../form/Select";
import Input from "../../form/Input";
import { t } from "i18next";
import { toast } from "sonner";
import getMatchStatus from "./status";

const MatchFormDialog = ({
  setRounds,
  tournament,
  rounds,
  round,
  open,
  onOpenChange,
  match,
}) => {
  const [loading, setLoading] = useState(false);
  const { tournamentId } = useParams();
  const selfMatchErrorMessage = t("entities.match.errors.self_match");
  const formRef = useRef({});
  const matchStatus = getMatchStatus();
  const handleDialogClose = () => {
    onOpenChange(false);
    formRef.current = {};
  };
  const handleMatchError = (error) => {
    if (error?.response?.data?.message) {
      if (error.response.data.message.includes("no_self_play")) {
        return toast.error(selfMatchErrorMessage);
      }
      if (error.response.data.message.includes("unique_round")) {
        return toast.error(
          t("entities.match.errors.already_plays_in_this_round"),
        );
      }
    }
  };

  const handleFinally = () => {
    setLoading(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const user_one_id = formData.get("user_one_id");
    const user_two_id = formData.get("user_two_id");
    const frames_count = formData.get("frames_count");
    const status = formData.get("status");
    const round_id = (match && match["round-id"]) || round["round-id"];

    if (user_one_id === user_two_id) {
      toast.error(selfMatchErrorMessage);
      return;
    }
    const payload = {
      round_id,
      user_one_id,
      user_two_id,
      frames_count,
      status,
    };
    formRef.current = payload;
    setLoading(true);
    if (match) {
      payload.match_id = match["match-id"];
      apiClient
        .put(
          `/tournaments/${tournamentId}/rounds/${round_id}/matches/${match["match-id"]}`,
          payload,
        )
        .then((res) => {
          setRounds(
            rounds.map((round) => {
              if (round["round-id"] === res.data["round-id"]) {
                return {
                  ...round,
                  matches: round.matches.map((m) => {
                    if (m["match-id"] === res.data["match-id"]) {
                      return res.data;
                    }
                    return m;
                  }),
                };
              }
              return round;
            }),
          );
          toast.success(t("entities.match.crud_success.update"));
          handleDialogClose();
        })
        .catch(handleMatchError)
        .finally(handleFinally);
    } else {
      apiClient
        .post(
          `/tournaments/${tournamentId}/rounds/${round_id}/matches`,
          payload,
        )
        .then((res) => {
          setRounds(
            rounds.map((round) => {
              if (round["round-id"] === res.data["round-id"]) {
                return { ...round, matches: [...round.matches, res.data] };
              }
              return round;
            }),
          );
          toast.success(t("entities.match.crud_success.create"));
          handleDialogClose();
        })
        .catch(handleMatchError)
        .finally(handleFinally);
    }
  };
  return (
    <Dialog.Root open={open} onOpenChange={handleDialogClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black opacity-40" />
        <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full max-w-lg mx-auto px-4">
          <div className="bg-white rounded-md shadow-lg px-4 py-6 flex flex-col gap-4">
            <div className="flex flex-col justify-between gap-4">
              <Dialog.Title className="text-lg font-medium text-gray-800">
                {t("entities.match.crud.create")}
              </Dialog.Title>
              {loading ? (
                <Loading />
              ) : (
                <form onSubmit={onSubmit} className="flex flex-col gap-4">
                  <Select
                    label={`${t("labels.player")} 1`}
                    name={"user_one_id"}
                    options={tournament.players.map((player) => ({
                      value: player["user-id"],
                      text: player.name || player.email,
                    }))}
                    defaultValue={
                      (match &&
                        match["user-one"] &&
                        match["user-one"]["user-id"]) ||
                      formRef.current.user_one_id
                    }
                  />
                  <Select
                    label={`${t("labels.player")} 2`}
                    name={"user_two_id"}
                    options={tournament.players.map((player) => ({
                      value: player["user-id"],
                      text: player.name || player.email,
                    }))}
                    defaultValue={
                      (match &&
                        match["user-two"] &&
                        match["user-two"]["user-id"]) ||
                      formRef.current.user_two_id
                    }
                  />
                  <Select
                    name="status"
                    label={t("labels.status")}
                    options={matchStatus}
                    defaultValue={
                      (match && match.status) ||
                      matchStatus[0].value ||
                      formRef.current.status
                    }
                  />
                  <Input
                    placeholder="ex: 3"
                    label={t("labels.frames_count")}
                    id="frames_count"
                    name="frames_count"
                    required
                    defaultValue={
                      (match && match["frames-count"]) ||
                      formRef.current.frames_count ||
                      (round && round["default-frames-count"]) ||
                      tournament["default-frames-count"]
                    }
                  />
                  <div className="inline-block">
                    <Button type="submit" color="primary">
                      {t("entities.match.crud.create")}
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default MatchFormDialog;
