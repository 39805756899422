import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Avatar } from "../../../assets/icons/avatar.svg";

const UserListItem = ({ avatarSrc, name, link }) => {
  return (
    <li className="flex items-center gap-4 px-4 py-3">
      <div className="self-start">
        {link ? (
          <Link
            to={link}
            className="relative inline-flex h-8 w-8 items-center justify-center rounded-full text-white"
          >
            <div
              alt="user name"
              title="user name"
              width="32"
              height="32"
              className="max-w-full rounded-full"
            >
              <Avatar src={avatarSrc} />
            </div>
            <span className=" absolute -bottom-0.5 -right-0.5 inline-flex items-center justify-center gap-1 rounded-full border-2 border-white bg-pink-500 p-1 text-sm text-white">
              <span className="sr-only"> offline </span>
            </span>
          </Link>
        ) : (
          <div
            alt="user name"
            title="user name"
            width="32"
            height="32"
            className="max-w-full rounded-full"
          >
            <Avatar src={avatarSrc} />
          </div>
        )}
      </div>

      <div className="flex min-h-[2rem] flex-col items-start justify-center gap-0 w-full truncate">
        <h4 className="text-base text-slate-700 w-full truncate">{name}</h4>
      </div>
    </li>
  );
};

export default UserListItem;
