import React, { useState } from "react";
import Input from "../../form/Input";
import { useNavigate, useParams } from "react-router";
import Button from "../../Button";
import CustomDatepicker from "../../form/CustomDatepicker";
import apiClient from "../../../api";
import SectionHeader from "../../SectionHeader";
import Select from "../../form/Select";
import { useTranslation } from "react-i18next";

const TournamentForm = () => {
  const { hallId } = useParams();
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [, setErrors] = useState(null);

  const handleStartDateChange = (date) => {
    setDates({ ...dates, startDate: date });
  };
  const handleEndDateChange = (date) => {
    setDates({ ...dates, endDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get("name");
    const description = formData.get("description");
    const default_frames_count = formData.get("default_frames_count");
    const bracket_type = formData.get("bracket_type");
    const payload = {
      name,
      description,
      start_time: dates.startDate.startDate,
      end_time: dates.endDate.startDate,
      hall_id: hallId,
      default_frames_count,
      bracket_type,
    };
    setLoading(true);
    await apiClient
      .post(`/tournaments`, payload)
      .then((res) => {
        navigate(`/tournaments/${res.data["tournament-id"]}`);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <SectionHeader
        title={t("entities.tournament.crud.create")}
        description={t("entities.tournament.form.heading")}
      />
      {loading ? (
        <loading />
      ) : (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <Input
            placeholder={t("entities.tournament.form.name")}
            name="name"
            required
          />
          <Input
            placeholder={t("entities.tournament.form.description")}
            name="description"
            required
          />
          <Input
            placeholder={t("entities.tournament.form.default_frames_count")}
            name="default_frames_count"
            type="number"
            min="1"
            required
          />
          <CustomDatepicker
            value={dates.startDate}
            asSingle={true}
            primaryColor={"blue"}
            inputClassName="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border 'border-gray-200 focus:border-gray-400 placeholder-gray-500 text-sm focus:outline-none  focus:bg-white"
            onChange={handleStartDateChange}
            displayFormat={"DD/MM/YYYY"}
            useRange={false}
            placeholder={t("entities.tournament.form.starts_at")}
            required
          />
          <CustomDatepicker
            value={dates.endDate}
            asSingle={true}
            primaryColor={"blue"}
            inputClassName="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border 'border-gray-200 focus:border-gray-400 placeholder-gray-500 text-sm focus:outline-none  focus:bg-white"
            onChange={handleEndDateChange}
            placeholder={t("entities.tournament.form.ends_at")}
            useRange={false}
            required
            displayFormat={"DD/MM/YYYY"}
          />
          <Select
            name="bracket_type"
            options={[
              {
                value: "single_elimination",
                text: t("entities.tournament.form.single_elimination"),
              },
              {
                value: "double_elimination",
                text: t("entities.tournament.form.double_elimination"),
              },
            ]}
          />
          <div>
            <Button type="submit" color="primary">
              {t("entities.tournament.crud.create")}
            </Button>
          </div>
        </form>
      )}
    </>
  );
};

export default TournamentForm;
