import apiClient from "../../api";
import Input from "../../components/form/Input";
import { useState } from "react";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [errors, setErrors] = useState({});
  const [result, setResult] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors({});
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");

    apiClient
      .post("/auth/forgot-password", { email })
      .then((_) => {
        setResult(true);
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };
  return (
    <>
      <div className="mt-2 flex flex-col items-center">
        <div className="w-full flex-1">
          <div className="my-8 border-b text-center">
            <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
              Esqueci a minha senha
            </div>
          </div>
          {result ? (
            <div>
              <div
                className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
                role="alert"
              >
                <p className="font-bold">Solicitação recebida com sucesso!</p>
                <p className="text-sm">
                  Caso o email que você inseriu possua uma conta relacionada,
                  uma mensagem será enviada ao mesmo com um link para a
                  definição da nova senha.
                </p>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <Input
                  className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  errors={errors}
                />

                <button
                  type="submit"
                  className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                >
                  <span className="ml-3">Redefinir minha senha</span>
                </button>
              </div>
            </form>
          )}
          <Link
            to="/auth/login"
            type="submit"
            className="mt-5 tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none  bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
          >
            <span className="ml-3">Acessar minha conta</span>
          </Link>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
