import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import useAuth from "../auth/useAuth";

const Logout = () => {
  const { clearToken } = useAuth();
  const navigate = useNavigate();

  // Function to handle logout
  const handleLogout = useCallback(() => {
    clearToken(); // Clear the authentication token
    navigate("/", { replace: true }); // Navigate to the home page ("/") with replace option set to true
  }, [clearToken, navigate]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <>Saindo...</>;
};

export default Logout;
