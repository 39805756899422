import { useEffect, useMemo, useState } from "react";
import AuthContext from "./AuthContext";
import apiClient from "../api";

function AuthProvider({ children }) {
  const [token, setToken_] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token && !user && !loading) {
      setLoading(true);
      apiClient
        .get("/auth/me")
        .then((res) => {
          if (!res.data) {
            window.location.href = "/auth/login";
            return;
          }
          setUser(res.data);
        })
        .catch((error) => {
          console.error(error);
          setToken(null);
          window.location.href = "/auth/login";
          return;
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, loading, user]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const setToken = (newToken) => {
    if (newToken) {
      localStorage.setItem("token", newToken);
      apiClient.defaults.headers.Authorization = `Bearer ${newToken}`;
    } else {
      localStorage.removeItem("token");
      delete apiClient.defaults.headers.Authorization;
    }
    setToken_(newToken);
  };

  const clearToken = () => {
    localStorage.removeItem("token");
    delete apiClient.defaults.headers.Authorization;
    setToken_(null);
  };

  useEffect(() => {
    if (token) {
      const requestInterceptor = apiClient.interceptors.request.use(
        (config) => {
          const storedToken = token;
          if (storedToken) {
            config.headers.Authorization = `Bearer ${storedToken}`;
          } else {
            delete config.headers.Authorization;
          }
          return config;
        },
        (error) => Promise.reject(error),
      );

      return () => {
        apiClient.interceptors.request.eject(requestInterceptor);
      };
    }
  }, [token]);

  const contextValue = useMemo(
    () => ({
      token,
      loading,
      user,
      setToken,
      clearToken,
    }),
    [token, user, loading],
  );
  if ((!token && localStorage.getItem("token")) || loading) {
    return null;
  }
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;
