import React from "react";
import { useTranslation } from "react-i18next";
const statusComponent = {
  scheduled: {
    bgColor: "bg-blue-100",
    textColor: "text-blue-800",
    circleColor: "bg-blue-800",
    text: "Agendado",
  },
  ongoing: {
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-800",
    circleColor: "bg-yellow-800",
    text: "Em andamento",
  },
  finished: {
    bgColor: "bg-green-100",
    textColor: "text-green-800",
    circleColor: "bg-green-800",
    text: "Finalizado",
  },
  canceled: {
    bgColor: "bg-red-100",
    textColor: "text-red-800",
    circleColor: "bg-red-800",
    text: "Cancelado",
  },
};
const Status = ({ status }) => {
  const classes = statusComponent[status];
  const { t } = useTranslation();

  return (
    <div>
      <span
        className={`inline-flex items-center ${classes.bgColor} ${classes.textColor} text-xs font-medium px-2.5 py-0.5 rounded-full `}
      >
        <span
          className={`w-2 h-2 me-1 ${classes.circleColor} rounded-full`}
        ></span>
        {t(`entities.match.status.${status}`)}
      </span>
    </div>
  );
};

export default Status;
