import React, { useState } from "react";
import SectionHeader from "../../SectionHeader";
import { useLoaderData } from "react-router";
import Alert from "../../common/Alert";
import moment from "moment";
import { Bracket } from "@sportsgram/brackets";
import * as Accordion from "@radix-ui/react-accordion";
import RoundFormDialog from "../rounds/RoundFormDialog";
import MatchFormDialog from "../matches/MatchFormDialog";

import MatchCard from "../matches/MatchCard";
import IconButton from "../../IconButton";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import FormatedDate, { formatedLocaleDate } from "../../FormatedDate";
import apiClient from "../../../api";
import DeleteRoundModal from "../rounds/DeleteRoundModal";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

const Brackets = ({ rounds }) => {
  return <Bracket rounds={rounds} />;
};

const Rounds = () => {
  const { tournament, rounds: defaultRounds } = useLoaderData();
  const [rounds, setRounds] = useState(defaultRounds);
  const [matchFormOpen, setMatchFormOpen] = useState(false);
  const [roundFormOpen, setRoundFormOpen] = useState(false);
  const [selectedRound, setSelectedRound] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleDeleteRound = (round) => {
    setLoading(true);
    apiClient
      .delete(
        `/tournaments/${tournament["tournament-id"]}/rounds/${round["round-id"]}`,
      )
      .then((_) => {
        setRounds(rounds.filter((r) => r["round-id"] !== round["round-id"]));
        toast.success(t("entities.round.crud_success.delete"));
      })
      .catch((_) => {
        toast.error(t("entities.round.crud_error.delete"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDeleteMatch = (match) => {
    setLoading(true);
    apiClient
      .delete(
        `/tournaments/${tournament["tournament-id"]}/rounds/${match["round-id"]}/matches/${match["match-id"]}`,
      )
      .then((_) => {
        setRounds(
          rounds.map((round) => {
            if (round["round-id"] === match["round-id"]) {
              return {
                ...round,
                matches: round.matches.filter(
                  (m) => m["match-id"] !== match["match-id"],
                ),
              };
            }
            return round;
          }),
        );
        toast.success(t("entities.match.crud_success.delete"));
      })
      .catch((_) => {
        toast.error(t("entities.match.crud_error.delete"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleEditMatch = (match) => {
    setSelectedMatch(match);
    setMatchFormOpen(true);
  };
  const handleMatchDialogOpen = (open) => {
    setMatchFormOpen(open);
    setSelectedMatch(null);
  };

  const handleEditRound = (round) => {
    setSelectedRound(round);
    setRoundFormOpen(true);
  };
  const handleRoundDialogOpen = (open) => {
    setRoundFormOpen(open);
    setSelectedRound(null);
  };

  const handleNewRoundMatch = (round) => {
    setSelectedRound(round);
    setMatchFormOpen(true);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <MatchFormDialog
        rounds={rounds}
        round={selectedRound}
        open={matchFormOpen}
        onOpenChange={handleMatchDialogOpen}
        handleDeleteMatch={handleDeleteMatch}
        setRounds={setRounds}
        tournament={tournament}
        match={selectedMatch}
      />

      <SectionHeader
        title={`${tournament.name} - ${t("entities.round.plural_name")}`}
        description={t("entities.round.page.description")}
      />

      <div className="py-4 flex flex-col gap-4">
        <RoundFormDialog
          open={roundFormOpen}
          round={selectedRound}
          setRounds={setRounds}
          onOpenChange={handleRoundDialogOpen}
          tournament={tournament}
          rounds={rounds}
        />

        {rounds && rounds.length === 0 ? (
          <Alert
            type="info"
            title={t("entities.round.page.empty_list")}
            dismissible={false}
          />
        ) : (
          <div className="w-full divide-y divide-gray-200">
            {rounds.map((round, idx) => {
              return (
                <Accordion.Root
                  type="single"
                  key={round["round-id"]}
                  defaultValue={rounds[0]["round-id"]}
                  collapsible
                  className="shadow-lg border rounded-md duration-300 hover:shadow-sm mb-4"
                >
                  <Accordion.Item value={round["round-id"]}>
                    <Accordion.Header className="flex gap-4 items-center bg-gray-000 text-gray-600 font-medium border-b py-2 px-4 w-full cursor-pointer">
                      <Accordion.Trigger asChild>
                        <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center w-full">
                          <span className="font-bold">
                            {t("entities.round.name")}{" "}
                            {round["round-number"] || idx} -{" "}
                          </span>
                          <span>
                            <FormatedDate date={round["start-time"]} />
                          </span>
                          {round["end-time"] && (
                            <span>
                              <FormatedDate date={round["end-time"]} />
                            </span>
                          )}
                          <div className="md:ml-auto flex gap-2">
                            <IconButton
                              icon={<AddIcon />}
                              onClick={(e) => {
                                e.preventDefault();
                                handleNewRoundMatch(round);
                              }}
                            >
                              {t("entities.match.crud.create")}
                            </IconButton>
                            <IconButton
                              icon={<EditIcon />}
                              onClick={(e) => {
                                e.preventDefault();
                                handleEditRound(round);
                              }}
                            />
                            <DeleteRoundModal
                              onConfirm={(e) => {
                                e.preventDefault();
                                handleDeleteRound(round);
                              }}
                            />
                          </div>
                        </div>
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content className="p-4">
                      {round.matches.length === 0 ? (
                        <Alert
                          type="info"
                          title={t("entities.round.page.no_matches")}
                          dismissible={false}
                        />
                      ) : (
                        <div className="flex flex-wrap -m-2 mb-6 flex-wrap ">
                          {round.matches.map((match, idx) => {
                            return (
                              <MatchCard
                                idx={idx + 1}
                                key={match["match-id"]}
                                match={match}
                                handleDeleteMatch={handleDeleteMatch}
                                handleEditMatch={handleEditMatch}
                              />
                            );
                          })}
                        </div>
                      )}
                    </Accordion.Content>
                  </Accordion.Item>
                </Accordion.Root>
              );
            })}
          </div>
        )}
        <Brackets
          rounds={rounds.map((round) => {
            return {
              title: `Rodada ${round["round-number"]}`,
              seeds: round.matches.map((match) => {
                return {
                  id: match["match-id"],
                  date: formatedLocaleDate(round["start-time"]),
                  teams: [
                    { name: match["user-one"].name || match["user-one"].email },
                    { name: match["user-two"].name || match["user-two"].email },
                  ],
                };
              }),
            };
          })}
        />
      </div>
    </div>
  );
};

export default Rounds;
