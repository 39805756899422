import { useLoaderData } from "react-router";
import Alert from "../../common/Alert";
import HallsForm from "./HallsForm";
import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import ClubImg from "../../../assets/images/club.webp";
import SectionHeader from "../../SectionHeader";
import Card from "../../Card";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Provider = ({ children }) => {
  return (
    <APIProvider apiKey={"AIzaSyCpw7lioraxBNkr26fcBCpbWTquBK9aOnE"}>
      {children}
    </APIProvider>
  );
};

const Halls = () => {
  const [halls, setHalls] = useState(useLoaderData());
  const places = useMapsLibrary("places");
  const { t } = useTranslation();
  const onNewHall = (hall) => {
    setHalls([...halls, hall]);
  };
  if (!places) return null;
  return (
    <div>
      <SectionHeader
        title={t("entities.hall.page.title")}
        description={t("entities.hall.page.description")}
      />

      <div className="flex flex-col lg:flex lg:flex-row gap-8 py-8">
        <div className="w-full lg:w-1/2 flex flex-wrap gap-4">
          {halls.length === 0 && (
            <div className="w-full">
              <Alert
                className="w-full"
                type="info"
                message={t("entities.hall.page.empty")}
              />
            </div>
          )}
          {halls.map((h) => {
            return (
              <div key={h["hall-id"]} className="w-full">
                <Card
                  title={h.name}
                  linkTo={`/halls/${h["hall-id"]}`}
                  icon={<img src={ClubImg} alt="clube" />}
                  footer={
                    h["formatted-address"] && (
                      <div className="text-sm text-gray-600 flex items-center gap-6">
                        <span className="flex items-center gap-2">
                          <svg
                            className="w-5 h-5 text-gray-500"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.05025 4.05025C7.78392 1.31658 12.2161 1.31658 14.9497 4.05025C17.6834 6.78392 17.6834 11.2161 14.9497 13.9497L10 18.8995L5.05025 13.9497C2.31658 11.2161 2.31658 6.78392 5.05025 4.05025ZM10 11C11.1046 11 12 10.1046 12 9C12 7.89543 11.1046 7 10 7C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11Z"
                              fill="#9CA3AF"
                            />
                          </svg>

                          {h["formatted-address"]}
                        </span>
                      </div>
                    )
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="w-full lg:w-1/2">
          <HallsForm onSuccess={onNewHall} className="w-full" />
        </div>
      </div>
    </div>
  );
};
const HallsWithMaps = () => (
  <Provider>
    <Halls />
  </Provider>
);
export default HallsWithMaps;
