import { Outlet } from "react-router-dom";
import Sidebar from "../components/app/Sidebar";
import useAuth from "../auth/useAuth";

const AppLayout = () => {
  const { token } = useAuth();

  if (!token) {
    return null;
  }
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col w-full h-screen overflow-y-auto md:pl-80">
        <div className="content p-4 mt-12 md:mt-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
