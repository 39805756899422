import { useCallback, useEffect, useRef, useState } from "react";
import Input from "../../form/Input";
import apiClient from "../../../api";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import SelectInput from "../../SelectInput";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";

const HallLocation = ({ setAddressData, reset }) => {
  const [placeDetails, setPlaceDetails] = useState({});
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    debounce: 700,
    apiKey: "AIzaSyCpw7lioraxBNkr26fcBCpbWTquBK9aOnE",
  });

  useEffect(() => {
    if (reset) {
      setPlaceDetails({});
      setValue("");
    }
  }, [reset]);

  useEffect(() => {
    if (placeDetails?.geometry) {
      setAddressData({
        location: Object.values(placeDetails.geometry.location.toJSON()).join(
          ",",
        ),
        formatted_address: placeDetails.formatted_address,
        google_place_id: placeDetails.place_id,
      });
    }
  }, [placeDetails, setAddressData]);

  const getPlaceDetails = useCallback(
    async (placeId) => {
      if (placesService?.getDetails) {
        await placesService.getDetails({ placeId }, (place, status) => {
          if (status === "OK") {
            setPlaceDetails(place);
          }
        });
      }
    },
    [placesService],
  );

  useEffect(() => {
    if (value) {
      getPlaceDetails(value);
    }
  }, [value, getPlaceDetails]);

  return (
    <>
      <SelectInput
        loading={isPlacePredictionsLoading}
        onValueChange={setValue}
        placeholder={t("entities.hall.form.location")}
        reset={reset}
        onSearch={(input) => getPlacePredictions({ input })}
        options={placePredictions.map((place) => ({
          value: place.place_id,
          text: place.description,
        }))}
      />
    </>
  );
};
const HallsForm = ({ onSuccess }) => {
  const [errors, setErrors] = useState({});
  const [addressData, setAddressData] = useState();
  const formRef = useRef({});
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const name = formData.get("name");
    formRef.current = { name };
    setLoading(true);
    apiClient
      .post("/halls", { name, ...addressData })
      .then((res) => {
        onSuccess(res.data);
        // Reset form fields
        event.target.reset();
        setAddressData(null);
        setErrors({});
        setReset(true); // Trigger reset for HallLocation component
        setTimeout(() => setReset(false), 0);
      })
      .catch((error) => {
        setErrors(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="text-base text-gray-800 font-semibold mb-4">
        {t("entities.hall.crud.create")}
      </div>
      <div className="flex flex-col gap-4 justify-center items center">
        <Input
          type="text"
          name="name"
          errors={errors}
          defaultValue={formRef.current.name}
          required
          placeholder={t("entities.hall.form.name")}
        />
        <HallLocation
          setAddressData={setAddressData}
          setLoading={setLoading}
          reset={reset}
        />
        {addressData && (
          <button
            type="submit"
            disabled={loading || !addressData}
            className="mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
          >
            {loading ? (
              <Loading />
            ) : (
              <>
                <svg
                  className="w-6 h-6 -ml-2"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                  <circle cx="8.5" cy="7" r="4" />
                  <path d="M20 8v6M23 11h-6" />
                </svg>
                <span className="ml-3">{t("entities.hall.crud.create")}</span>
              </>
            )}
          </button>
        )}
      </div>
    </form>
  );
};
export default HallsForm;
