const Input = ({
  type,
  name,
  defaultValue,
  placeholder,
  errors,
  ref,
  label,
  id,
  ...props
}) => {
  const hasErrors = errors && !!errors[name];
  return (
    <div>
      {label && (
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor={id ? id : name ? name : undefined}
        >
          {label}
        </label>
      )}
      <input
        className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${hasErrors ? "border-red-500" : "border-gray-200 focus:border-gray-400"} placeholder-gray-500 text-sm focus:outline-none  focus:bg-white`}
        type={type}
        name={name}
        placeholder={placeholder}
        ref={ref}
        {...props}
        value={props.value}
        defaultValue={defaultValue}
      />
      {hasErrors && (
        <p className="text-sm text-red-600 dark:text-red-500">{errors[name]}</p>
      )}
    </div>
  );
};

export default Input;
